<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">用户评论</h4>
            <div class="row mt-4">
              <!-- <div style="display: flex;margin-bottom: 20px;width: 300px;">

                <el-input v-model="searchInput" placeholder="请输入用户名称"></el-input>
                <el-button @click="SearchParent">搜索</el-button>
              </div> -->

            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields"  responsive="sm" per-page=0 :current-page="currentPage"
                :sort-by.sync="sortBy" :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(img_url)="data">
                  <span v-html="data.value"></span>
                </template>

                <template v-slot:cell(piaoxi)="data">
                  <b-badge style="    padding: 5px 10px;
                  margin-right: 5px;" variant="primary" v-for="(item,idx) in data.value" :key="idx">{{item}}</b-badge>
                </template>
                
                <template v-slot:cell(tool)="data">
                  <b-button @click="EditRows(data.item,data.index)" style="margin-right: 10px;" variant="warning">编辑
                  </b-button>
                  <b-button @click="delRows(data.item.name,data.index)" style="margin-right: 10px;" variant="danger">删除
                  </b-button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>

    <el-dialog title="修改信息" :visible.sync="modalShow" top="15px" width="40%" :before-close="handleClose">
      <el-form ref="form" :model="EditRowData" label-width="80px">
        <el-form-item label="集团名称">
          <el-input v-model="EditRowData.name"></el-input>
        </el-form-item>
        <el-form-item label="票系">
          <el-input v-model="EditRowData.piaoxi"></el-input>
        </el-form-item>
        <el-form-item label="是否上市">
          <el-switch v-model="EditRowData.Listed_of" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="上市编号">
          <el-input v-model="EditRowData.Listing_number"></el-input>
        </el-form-item>
        <el-form-item label="国内评级">
          <el-input v-model="EditRowData.guonpj"></el-input>
        </el-form-item>
        <el-form-item label="国际评级">
          <el-input v-model="EditRowData.guojpj"></el-input>
        </el-form-item>
        <el-form-item label="行业类别">
          <el-select v-model="EditRowData.leib" placeholder="请选择行业类别">
            <el-option label="地产" value="地产"></el-option>
            <el-option label="建筑" value="建筑"></el-option>
            <el-option label="大型" value="大型"></el-option>
            <el-option label="银票" value="银票"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Logo">
          <div @click="EditLogo" style="width: 200px;height:200px;display: flex;">
            <img style="width:100%;height: 100%;" :src="utils.Img_Url + value+EditRowData.img_url" alt="">
          </div>
        </el-form-item>
        <input ref="LogoUploads" id="LogoFile" @change="ChangeLogo" type="file">

        <el-form-item label="企业性质">
          <el-select v-model="EditRowData.enterprise" placeholder="请选择企业性质">
            <el-option label="民营" value="民营"></el-option>
            <el-option label="央企" value="央企"></el-option>
            <el-option label="国企" value="国企"></el-option>
            <el-option label="外资" value="外资"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改</el-button>
          <el-button @click="modalShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import utils from "@/utils/index"
  // import { tableData } from "./dataAdvancedtable";
  /**
   * Advanced table component
   */
  export default {
    page: {
      title: "用户评论",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        modalShow: false,
        searchInput: "",
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        tableData: '',
        title: "用户评论",
        items: [
          {
            text: "用户列表",
            href: "/user/userlist"
          },
          {
            text: "用户评论",
            active: true
          }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: [
          { key: "user_id", label: "用户名" },
          { key: "title_all", label: "评论内容" },
          { key: "go_time", label: "发布时间", sortable: true },
          {
            key: "tool", label: "工具栏"
          },
        ],
        dialogVisible: false,
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: "",
        uuid:""
      };
    },
    computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
    },
    created() {
      console.log(process.env)
      this.utils = utils
      this.uuid = this.$route.params.uuid
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {
          this.onSubmit(); //登录事件
        }
      },
      // 跳转子公司
      SubCompany() {
        this.$router.push({
          name: "Index",
          // params: { teamid: teamid, keyPath: this.keyPath },
        });
      },
      SearchParent() {
        console.log(123)
        _API_.get_parent_company({
          name: this.searchInput
        }).then((res) => {
          this.page.total = 10
          this.tableData = res.data
        })
      },
      onSubmit() {
        let that = this
        let fd = new FormData()// FormData 对象
        fd.append('id', this.EditRowData.id)// 文件对象
        fd.append('name', this.EditRowData.name)// 文件对象
        fd.append('piaoxi', this.EditRowData.piaoxi)// 文件对象
        fd.append('leib', this.EditRowData.leib)// 文件对象
        fd.append('Listed_of', this.EditRowData.Listed_of ? 1 : 0)// 文件对象
        fd.append('Listing_number', this.EditRowData.Listing_number)// 文件对象
        fd.append('enterprise', this.EditRowData.enterprise)// 文件对象
        fd.append('guojpj', this.EditRowData.guojpj)// 文件对象
        fd.append('guonpj', this.EditRowData.guonpj)// 文件对象

        if (this.LogoFile) {

          fd.append('img_url', this.LogoFile)// 文件对象
        }

        _API_.ParentCompanyEdit(fd).then((res) => {

          if (res.code == 200) {
            this.$set(this.tableData, this.Editindex, res.data[0])
            this.$message({
              message: res.msg,
              type: 'success',
              duration: 1000,
              onClose() {
                that.modalShow = false
              }
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.LogoFile = ""
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 1000
            });
            document.getElementById('LogoFile').outerHTML = document.getElementById('LogoFile').outerHTML
            this.LogoFile = ""
          }

        })


      },
      EditLogo() {
        this.$refs.LogoUploads.dispatchEvent(new MouseEvent('click'))
      },
      ChangeLogo() {
        this.LogoFile = this.$refs.LogoUploads.files[0]
      },
      handleClose() {
        this.modalShow = false
      },
      /**
       * Search the table data with search input
       */
      delRows(item,) {
        _API_.ParentCompany({
          name: item,
        }).then((res) => {
          console.log(res)
          this.CheckParentCompany()
        })
      },
      EditRows(Item, Editindex) {
        this.modalShow = true
        this.Editindex = Editindex
        Item.Listed_of = Item.Listed_of == 1 ? true : false
        console.log(Item.Listed_of)
        this.EditRowData = Item
      },
      ModalTrue() {
        this.modalShow = false
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CheckParentCompany() {
        _API_.UserLeave({
          uuid: this.uuid,
        }).then((res) => {
          if(res.code == 200)
          {
            this.tableData = res.data
            this.page.total = res.count
          }else
          {
            utils.ShowMsg(res.msg,'error')
          }
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>